import {
  Body,
  Column,
  Container,
  Head,
  Heading,
  Html,
  Img,
  Link,
  Preview,
  Row,
  Section,
  Text,
  Tailwind,
} from "@react-email/components";
import { Fragment } from "react/jsx-runtime";

interface ContactFormData {
  name: string;
  email: string;
  message?: string;
}

export const LivsPeppContactEmail = (props: ContactFormData) => {
  return (
    <Html>
      <Head />
      <Preview>Kontaktförfrågan från {props.name}</Preview>
      <Tailwind
        config={{
          theme: {
            extend: {
              colors: {
                brand: "#2250f4",
                offwhite: "rgb(255, 247, 242)",
                lightgray: "#efefef",
              },
              spacing: {
                0: "0px",
                20: "20px",
                45: "45px",
              },
            },
          },
        }}
      >
        <Body className="bg-offwhite text-base font-sans py-20">
          <Img
            src={`https://livspepp-new.pages.dev/livspepp-raster.png`}
            width="250"
            alt="Livspepp"
            className="mx-auto my-20"
          />
          <Container className="bg-white my-45 p-20 rounded-xl">
            <Heading className="text-center my-0 leading-8">
              Ny kontaktförfrågan från livspepp
            </Heading>

            <Section>
              <Row>
                <Text className="text-base">
                  <strong>{props.name}</strong> vill få kontakt med Livspepp,
                  känn peppen!
                  {props.message && (
                    <Text className="text-base">
                      {props.name} lämnade detta meddelandet:
                    </Text>
                  )}
                </Text>
                {props.message && (
                  <Text className="text-base bg-lightgray italic p-4 rounded-md whitespace-pre-line">
                    {props.message}
                  </Text>
                )}
              </Row>
            </Section>

            <Section className="text-center p-45">
              <Link
                href={`mailto:${props.email}`}
                className="bg-brand text-white rounded-lg py-3 px-[18px]"
              >
                {props.message ? "Svara" : "Maila"} {props.name}
              </Link>
            </Section>
          </Container>

          <Container className="mt-20">
            <Section>
              <Row>
                <Column className="text-center px-20">
                  <Link href="https://livspepp-new.pages.dev">Livspepp</Link>
                </Column>
              </Row>
            </Section>
            <Text className="text-center text-gray-400 mb-45">
              Livspepp jobbar med att skapa inre hållbarhet genom att visa på
              goda värderingar.
            </Text>
          </Container>
        </Body>
      </Tailwind>
    </Html>
  );
};

LivsPeppContactEmail.PreviewProps = {
  name: "Kalle Svensson",
  email: "kallesvensson@example.com",
  message: `Jag vill bara säga hur grym er sida är! Jag har precis börjat läsa era artiklar och känner redan hur motivationen ökar. Ni gör verkligen skillnad och jag uppskattar allt ni delar med er av. Tack för att ni sprider så mycket positiv energi och hjälper människor att hitta sitt bästa jag! 🙌✨

Ser fram emot att följa er resa och få ännu mer pepp!

Bästa hälsningar, Kalle`,
} satisfies ContactFormData;

export default LivsPeppContactEmail;
