<template>
  <form class="" @submit.prevent="sendEmail">
    <div class="space-y-5">
      <input name="name" type="text" placeholder="Namn"
        class="py-3 bg-transparent focus-visible:outline-none w-full placeholder:text-black border-b-2 border-black rounded-none"
        required />
      <input name="email" type="email" placeholder="E-post"
        class="py-3 bg-transparent focus-visible:outline-none w-full placeholder:text-black border-b-2 border-black rounded-none"
        required />
      <textarea name="message"
        class="py-3 bg-transparent focus-visible:outline-none w-full placeholder:text-black border-b-2 border-black rounded-none"
        placeholder="Hej livspepp..."></textarea>
    </div>
    <fieldset class="mt-8">
      <label>
        <input type="checkbox" name="gdpr" required />
        Jag godkänner att mina personuppgifter behandlas i enlighet med
        Livspepps
      </label>
      <a href="/integritetspolicy" class="underline">integritetspolicy.</a>
    </fieldset>
    <button class="bg-black text-white rounded-full px-5 py-2 w-full mt-4" :class="{
      'opacity-60': isSending,
    }" :disabled="isSending">
      {{ isSending ? "SKICKAR..." : "SKICKA" }}
    </button>
  </form>
</template>

<script setup lang="ts">
import { ref } from "vue";
import ky from "ky";
import { render } from "@react-email/render";
import ContactEmail from "../emails/ContactEmail.tsx";
const functionBase = import.meta.env.PUBLIC_FUNCTION_BASE || "";
const isSending = ref(false);

const emit = defineEmits(["error", "success"]);

async function sendEmail(event: Event) {
  const target = event.target as HTMLFormElement;
  const formData = new FormData(target);

  const contactName = formData.get("name");
  const contactEmail = formData.get("email");
  const contactMessage = formData.get("message");

  if (!contactName || !contactEmail) {
    throw new Error("Missing form data");
  }

  const template = await render(
    ContactEmail({
      name: contactName.toString(),
      email: contactEmail.toString(),
      message: contactMessage?.toString(),
    })
  );

  isSending.value = true;
  try {
    const response = await ky
      .post(functionBase + "/api/send-mail", {
        json: {
          message: template,
        },
      })
      .json();
    isSending.value = false;
    emit("success", response);
  } catch (err) {
    emit("error", err);
  }
}
</script>
